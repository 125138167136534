import React from "react";
import { Link } from "gatsby";
import Layout from "../../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo";

const BikeShelters = () => {
    return (
        <Layout>
                <Seo
      title="Bike Shelters & Compounds - BIKESAFE"
      description="Gone are the days where you have to leave your bike tied to an unsuspecting tree in the park, or to a lamp post in the street. A bike shelter or bike compound can be located in most public areas, but with more and more people owning a bike, there is a greater need and expectation from cycling enthusiasts for accessible cycle shelters."
    />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5 text-center uppercase">
                        Bike Shelters & Compounds
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        <p className="flow-text">
                        Gone are the days where you have to leave your bike tied to an unsuspecting tree in the park, or to a lamp post in the street. A bike shelter or bike compound can be located in most public areas, but with more and more people owning a bike, there is a greater need and expectation from cycling enthusiasts for accessible cycle shelters.
                        </p>
                        <p className="flow-text">Our extensive range of bike shelters are robust, contemporary and extremely practical. Whatever dimensions and style you’re looking for we’ll have the right cycle compound for you.</p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                <hr/>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/233">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/233/homepage-233.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Two Tier New York</h3>
                            <p>This stunning bike shelter comes with an anti-climb roof perfect for installations into public space. Supplied with two tier racks this cycle shelter is the best option on the market...</p>
                            <p className="shop-now">Get a Quote</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/38">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/38/homepage-38.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Two Tier Ark Shelter</h3>
                            <p>Designed to double the number of cycles you can fit in a relatively small space, with 2 rows of bike parking. This Cycle Shelter is available in a galvanised or powder coated RAL code colour...</p>
                            <p className="shop-now">Get a Quote</p>
                        </Link>
                    </Col>
                    <Col sm="12" lg="4" className="p-2">
                        <Link to="/product/22">
                            <StaticImage placeholder="blurred"  className="fluid" src="../product/22/homepage-22.jpg"></StaticImage>
                            <h3 className="uppercase mt-3">Two Tier Bristol Shelter</h3>
                            <p>This Bike Shelter comes with the best all-round features to suit any environment. Shelter available in a galvanised or polyester powder coated RAL code colour finish...</p>
                            <p className="shop-now">Get a Quote</p>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default BikeShelters;